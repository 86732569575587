// sass-utils
@import './sass-utils/fonts';

// vendors
@import './vendors/antd';

// base
@import './base/reset';

// variables
@import './variables';

// components
@import './components';

%navbar-text {
  font-weight: bold;
  font-size: 14px;
  @media (max-width: 800px) {
    font-size: 10px;
  }
}

h5 {
  margin-bottom: 0 !important;
}

.ant-tabs-tab {
  margin: 0 !important;
}

.fs-small {
  font-size: 12px;
}

.header {
  background-color: $navbar-background-color !important;
  padding-inline: 0% !important;
}

.navbar-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: $navbar-background-color;
  padding: 0 1rem 0 1rem;
}

.navbar-item {
  color: black;
}

.navbar-switch-text {
  @extend %navbar-text;
  color: $navbar-color;
  padding-right: 1rem;
}

.ant-menu-title-content {
  @extend %navbar-text;
  color: $navbar-color;
}

.navbar-switch {
  display: flex;
  align-items: center;
  width: auto;
  padding-right: 1rem;
}

.navbar-menu-a:hover {
  color: inherit; // otherwise antd will override the color
  text-decoration: none;
}

.ant-menu-item-only-child {
  @extend %navbar-text;
  color: $navbar-color;
  width: 100% !important;
}

.dimmed {
  position: relative;
}

.dimmed:after {
  content: ' ';
  z-index: 10;
  display: block;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.7);
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-grab {
  cursor: grab;
}

.cursor-auto {
  cursor: auto;
}

.cursor-help {
  cursor: help;
}

.dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
}

.pre-line {
  white-space: pre-line !important;
}

// pdf-viewer
.pdf-navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 1vh;
}

.react-pdf__Page__textContent {
  display: none;
}

// location-input && manual-location-input
.autocomplete-dropdown-container {
  position: absolute;
  z-index: 1;
  width: 100%;
  border-radius: 8px;
  padding: 0.2rem;
  border: $navbar-color 1px solid;
}

.suggestion-item {
  cursor: pointer;
}

.suggestion-item,
.suggestion-item--active {
  cursor: pointer;
}

.suggestion-item:hover,
.suggestion-item--active:hover {
  background-color: #e8e8e8;
}

.tab-content-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding-bottom: 10px;
}

.tab-content {
  flex-grow: 1;
  overflow-y: scroll;
}

.lookup-sider {
  width: calc(20vw) !important;
  max-width: 300px !important;
  min-width: 200px !important;
  flex: none !important;
}

.site-layout-background {
  height: calc(100vh - 97px);
  display: block;
}

.dashboard-layout-background {
  height: 100hv;
}

.ant-tabs-content-holder {
  height: 100%;
}

.ant-tabs-content {
  height: 100%;
}

.ant-tabs-tabpane-active {
  height: 100%;
}

.ant-collapse-content-box {
  padding: 0 !important;
}

// bird eats bug stays when the sidebar is open
.ant-float-btn {
  z-index: 9999;
}

.lookup-searchbar {
  margin: 1px 5px;
}

.text-light {
  color: grey !important;
}

.entity-picker-menu-item {
  margin: 0 !important;
  padding: 1px 16px;
  width: 100% !important;
  height: 25px !important;
  line-height: 25px !important;
}

.bold {
  font-weight: bold;
}

.loader-container {
  position: fixed;
  top: 35%; // aligned with icon in Documents tab & not touching the tab navigation
  left: 60%; // sider take spaces on the left
  transform: translate(-50%, -50%);
}

// select-multiselect-input: position "clear" icon on the left of "arrow" icon, not hover
.ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-clear {
  position: absolute;
  left: calc(100% - 40px);
}

.rich-inline-input div {
  border: 1px solid #d9d9d9;
  background-color: #ffffff;
  box-shadow: none !important;
  border-radius: 5px;
}

.rich-inline-input p {
  padding: 5px 10px;
}

.avatar-group {
  background-color: #fff;
  color: black;
  border-color: lightgrey;
  font-weight: 300;
  font-size: 12px;
}

.dashboard-element {
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 20px;
}

.dashboard-container {
  margin-bottom: 50px;
}

.dashboard-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1rem;
}

.dashboard-progress {
  width: 65%;
}

.dashboard-title {
  padding-bottom: 0.5rem;
}

.dashboard-header-title {
  color: #fff !important;
}

.dashboard-unknown-date,
.dashboard-unknown-step,
.unknown-avatar-member {
  color: red;
}

.dashboard-column-element {
  max-width: calc(12vw - 16px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.hidden-scroll-container {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.hidden-scroll-container::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.underline-hover:hover {
  text-decoration: underline;
}
